import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  useEffect(() => {
    // Existing scroll handler for header
    const handleScroll = () => {
      const header = document.querySelector('.main-header');
      if (window.scrollY > 150) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };

    // Modified scroll handler with special case for About section
    const handleNavClick = (e) => {
      if (e.target.hash) {
        e.preventDefault();
        const element = document.querySelector(e.target.hash);
        let headerOffset = 100; // Default offset for most sections

        // Special larger offset just for the About section
        if (e.target.hash === '#about') {
          headerOffset = -10; // Increased offset for About section
        }

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };

    // Modified header click handler
    const handleHeaderClick = (e) => {
      // Only trigger if clicking the header area, not the nav links
      if (!e.target.closest('nav')) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.querySelectorAll('nav a').forEach(anchor => {
      anchor.addEventListener('click', handleNavClick);
    });
    
    // Add click listener to entire header instead of just h1
    const header = document.querySelector('.main-header');
    header.addEventListener('click', handleHeaderClick);
    
    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.querySelectorAll('nav a').forEach(anchor => {
        anchor.removeEventListener('click', handleNavClick);
      });
      header.removeEventListener('click', handleHeaderClick);
    };
  }, []);

  // Add effect to handle video loading
  useEffect(() => {
    if (isVideoOpen) {
      // Add your video loading logic here
    }
  }, [isVideoOpen]);

  return (
    <div className="App">
      <header className="main-header">
        <h1>
          <span className="cursive-text">MG</span>
          <span className="regular-text">&nbsp;Music Academy</span>
        </h1>
        <nav>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#pricing">Lessons</a></li>
            <li><a href="#schedule">Schedule</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <main>
        <section id="hero" className="hero-section">
          <h2>Mariia Gushchina</h2>
          <h2>Personal Piano Lessons</h2>
          <p>Russian piano teacher offering personalized lessons for beginners and intermediate students</p>
        </section>

        <section id="about" className="about-section">
          <div className="about-content">
            <div className="about-text">
              <h2>About Mariia</h2>
              <p>With over two decades of experience in piano, Mariia Gushchina brings a rich blend of classical training and russian teaching methods to her lessons, creating a vibrant and inspiring environment for students.</p>
              <p>Mariia's journey has taken her through Moscow, Madrid, London, and New York, where she immersed herself in diverse cultural and musical influences. These experiences have shaped her distinctive teaching approach, balancing technical percision and creative expression.</p>
              <p>Her philosophy is rooted in the belief that pian is not just a skill but a source of profound personal growth and therapeutic value. Mariia is passionate about helping students of all ages discover their own musical voices while nurturing a deep appreciation for the timeless beauty of music </p>
              <ul className="credentials">
                <li>Graduated Russian Piano School</li>
                <li>20+ Years Piano Experience</li>
                <li>International Festival Winner</li>
              </ul>
              <button 
                className="video-link" 
                onClick={() => setIsVideoOpen(true)}
              >
                ♪ Watch Mariia Play
              </button>
            </div>
            <div className="about-image">
              <img src="/teacher-photo.jpg" alt="Mariia Gushchina teaching piano" />
            </div>
          </div>
        </section>

        {/* Video Modal */}
        {isVideoOpen && (
          <div className="video-modal" onClick={() => setIsVideoOpen(false)}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <button 
                className="close-button"
                onClick={() => setIsVideoOpen(false)}
              >
                ×
              </button>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/kAb5fjgNB9I?autoplay=1"
                title="Mariia Playing Piano"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}

        <section id="pricing" className="pricing-section">
          <h2>Lesson Packages</h2>
          <p className="pricing-subheader">Available Online or In-Person</p>
          <div className="pricing-cards">
            <div className="pricing-card">
              <h3>30 Minutes</h3>
              <div className="price">$40</div>
              <ul>
                <li>Perfect for beginners/small children</li>
                <li>Focused instruction</li>
                <li>Practice guidance</li>
              </ul>
            </div>

            <div className="pricing-card featured">
              <h3>45 Minutes</h3>
              <div className="price">$55</div>
              <ul>
                <li>Good for intermediate students</li>
                <li>Comprehensive lessons</li>
                <li>Detailed feedback</li>
              </ul>
            </div>

            <div className="pricing-card">
              <h3>60 Minutes</h3>
              <div className="price">$70</div>
              <ul>
                <li>In-depth instruction</li>
                <li>Weekly lessons</li>
                <li>Extended practice time</li>
              </ul>
            </div>
          </div>
          <p className="pricing-note">* All in-person classes include complementary tea service</p>
        </section>

        <section id="schedule" className="schedule-section">
          <h2>Schedule a Lesson</h2>
          <div className="schedule-content">
            <div className="schedule-info">
              <h3>How to Get Started</h3>
              <ol>
                <li>Choose your preferred lesson package</li>
                <li>Select an available time slot</li>
                <li>Complete a brief registration</li>
                <li>Receive confirmation email</li>
              </ol>
            </div>
            <div className="schedule-action">
              <p>Ready to begin your musical journey?</p>
              <a 
                href="https://calendly.com/your-calendly-link" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="schedule-button"
              >
                Schedule Now
              </a>
            </div>
          </div>
        </section>

        <section id="contact" className="contact-section">
          <div className="contact-content">
            <h2>Get in Touch</h2>
            <p className="contact-intro">Have questions? I'd love to hear from you.</p>
            <div className="contact-methods">
              <div className="contact-method">
                <div className="contact-icon">✉</div>
                <h3>Email</h3>
                <a href="mailto:mariiamusicacademy@gmail.com">mariiamusicacademy@gmail.com</a>
              </div>
              <div className="contact-method">
                <div className="contact-icon">📍</div>
                <h3>Location</h3>
                <p>Stamford, Connecticut</p>
              </div>
              <div className="contact-method">
                <div className="contact-icon">📞</div>
                <h3>Phone</h3>
                <a href="tel:+1-203-868-9907">+1 (203) 868-9907</a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
